@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-font-color: rgb(147, 157, 250);
  --primary-font-color-hover: rgb(82, 98, 245);
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial, sans-serif, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@keyframes typing {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}


@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@layer utilities {
  .animate-typing {
    display: inline-block;
    opacity: 0;
    animation-name: typing;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .animate-slideIn {
    display: inline-block;
    opacity: 0;
    animation-name: slideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .animate-slideUp {
    display: inline-block;
    opacity: 0;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .animate-expand {
    animation-name: expand;
    animation-delay: 0.1s;
    animation-duration: 3s;
  }
}

@keyframes expand {

  0%,
  100% {
    transform: scale(1);
  }

  14.29% {
    transform: scale(1.2);
  }

  28.57% {
    transform: scale(1);
  }

  28.57%,
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {

  0%,
  28.57% {
    transform: rotate(0deg);
  }

  12.86% {
    transform: rotate(4deg);
  }

  25% {
    transform: rotate(0deg);
  }

  42.86% {
    transform: rotate(-4deg);
  }

  75%,
  100% {
    transform: rotate(0deg);
  }
}

.checkbox:checked,
.checkbox:active {
  background-color: #939DFA;
  border-color: #939DFA;
}

.checkbox:checked:hover {
  background-color: #939DFA;
  border-color: #939DFA;
}

.checkbox:hover {
  background-color: #939DFA;
  border-color: #939DFA;
}

.checkbox:focus {
  background-color: #939DFA;
  border-color: #939DFA;
}

.checkbox:checked:focus {
  background-color: #939DFA;
  border-color: #939DFA;
}

.checkbox:not(:checked):focus {
  background-color: white;
  border-color: white;
}